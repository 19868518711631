<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <loader
      v-if="ShowProduct"
      object="#009bc2"
      color1="#ffffff"
      color2="#1763fd"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      disable-scrolling="false"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div
        class="card p-1 mb-1"
      >
        <b-alert variant="danger" show>
        <div class="alert-body" style="white-space: pre-line">
          กรุณาอ่านรายละเอียดก่อนสั่งซื้อ!!<br />
          ⏩ 1.รายการสั่งซื้อสินค้าสูงสุดได้ 50 รายการ / 1ร้านค้า เท่านั้น <br />
          ⏩ 2.ท่านสามารถสั่งซื้อสินค้าได้มากกว่า 1 ร้านค้า ใน 1 ใบสั่งซื้อ<br />
          ⏩ 3.แนะนำลูกค้าตรวจสอบความน่าเชื่อถือของร้านก่อนทำการสัั่งซื้อทุกครั้ง<br />
          ⏩ 4.หากสั่งร้านใหม่ๆไม่น่าเชื่อถือ แนะนำเปิดบริการ QC
          สินค้าเพื่อป้องกันปัญหาสินค้าไม่ตรงปก<br />
          ⏩ 5.ทางเราเป็นเพียงแค่ตัวกลางกดสั่งซื้อสินค้าขอสงวนสิทธิ์ไม่รับผิดชอบให้ทุกกรณี
        </div>
      </b-alert>
      </div>
      <div class="card p-1">
        <div class="d-flex row">
          <div class="XjROLg col-lg-5 col-md-5 col-sm-12">
            <div class="sticky">
              <skeleton-loader-vue
                v-if="!Product.external_image"
                type="rect"
                :width="400"
                :height="400"
                animation="wave"
              />

              <div class="img-show-first w-100">
                <img
                  :src="selectedImg"
                  alt="img-product"
                >
              </div>

              <span
                v-for="(item, index) in Product.external_image"
                :key="index"
                class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                :class="{ active: actv === index }"
                @click="imgShow(item.image), actImg(index)"
              >
                <img :src="item.thumnail">
              </span>
            </div>
          </div>

          <div class="layout-right ml-md-2 w-100 col-lg-6 col-md-6 col-sm-12">
            <div class="od-pc-offer-title-contain">
              <div class="title-content">
                <div class="title-first-column">
                  <div class="title-text">
                    <skeleton-loader-vue
                      v-if="!Product.product_name"
                      type="rect"
                      :width="600"
                      :height="20"
                      animation="wave"
                    />
                    {{ Product.product_name }}
                  </div>
                </div>
                <div class="title-first-column">
                  <div class="title-text">
                    <skeleton-loader-vue
                      v-if="!Product.shop_name"
                      type="rect"
                      :width="600"
                      :height="20"
                      animation="wave"
                    />
                    {{ $t("key-166") }}: {{ Product.shop_name }}
                  </div>
                </div>
                <div class="title-second-column">
                  <div class="title-text title-second-text" />
                </div>
              </div>
            </div>

            <div
              v-if="Product.url"
              class="mt-1 mb-1"
            >
              <button
                class="btn btn-gradient-warning mr-2"
                @click="openNewTab(Product.url)"
              >
                <i class="fa fa-link" /> {{ $t("key-167") }}
              </button>
              <button
                class="btn btn-gradient-success"
                @click="copyText(Product.url)"
              >
                <i class="fa fa-link" /> {{ $t("key-168") }}
              </button>
            </div>

            <div
              v-if="Product.priceRange && Product.priceRange.length > 0"
              class="od-pc-offer-price-contain"
            >
              <div class="od-pc-offer-price-common">
                <div class="price-content">
                  <div class="price-header">
                    <span class="price-name">{{ $t("price") }}</span><span class="num-name">.</span><span class="num-name">จำนวนสินค้า</span>
                  </div>
                  <div class="step-price-wrapper brlr-6">
                    <div
                      v-for="(Price, IndexPrice) in Product.priceRange"
                      :key="IndexPrice"
                      class="step-price-item"
                    >
                      <div class="price-box">
                        <span class="price-unit">¥</span><span class="price-text">{{ Price[1] }}</span>
                      </div>
                      <br>
                      <div class="price-box">
                        <span class="price-unit2">฿</span><span class="price-text2">{{
                          Commas(Price[1] * Rate)
                        }}</span>
                      </div>
                      <div class="step-unit-box">
                        <span
                          class="unit-text"
                        >{{
                           IndexPrice !== Product.priceRange.length - 1
                             ? `${Price[0]} - ${
                               Product.priceRange[IndexPrice + 1][0] - 1
                             }`
                             : `≥${Price[0]}`
                         }}
                          {{ Product.unit }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="od-pc-offer-price-contain"
            >
              <div class="od-pc-offer-price-common">
                <div class="price-content">
                  <div class="price-header">
                    <span class="price-name">{{ $t("price") }}</span>
                    <span class="num-name">.</span>
                  </div>
                  <div class="step-price-wrapper brlr-6">
                    <div class="step-price-item">
                      <div class="price-box">
                        <span class="price-unit">¥</span><span class="price-text">{{ Product.min_price }}</span>
                        <span class="price-text"> ~ </span>
                        <span class="price-unit">¥</span><span class="price-text">{{ Product.max_price }}</span>
                      </div>
                      <div class="price-box">
                        <span class="price-unit2">฿</span><span class="price-text2">{{
                          Commas(Product.min_price * Rate)
                        }}</span>
                        <span class="price-text2"> ~ </span>
                        <span class="price-unit2">฿</span><span class="price-text2">{{
                          Commas(Product.max_price * Rate)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="od-pc-offer-line">
              <div class="division-line" />
            </div>

            <div
              v-if="Product.color.title"
              class="anan-item-wrapper"
            >
              <div
                v-for="(item, index) in Product.color.list"
                :key="index"
                class="anan-item"
              >
                <div
                  class="anan-item-inner-wrapper"
                  :class="{ active: actv_color === index }"
                  @click="
                    imgShow(item.image), (actv_color = index), (Color = {id: item.id ,title: item.title || null} )
                  "
                >
                  <div
                    class="anan-img"
                    :style="`background: url(&quot;${item.image}&quot;) center center / contain no-repeat; width: 36px; height: 36px; margin-right: 2px;`"
                  />
                  <div
                    class="anan-name"
                    :title="item.image"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!Product.color.title.length"
              class="anan-item-wrapper"
            >
              <div
                v-for="(item, index) in Product.size.list"
                :key="index"
                class="anan-item"
              >
                <div
                  class="anan-item-inner-wrapper"
                  :class="{ active: actv_color === index }"
                  @click="
                    imgShow(item.image), (actv_color = index), (Color = {id: item.id ,title: item.title || null} )
                  "
                >
                  <div
                    class="anan-img"
                    :style="`background: url(&quot;${item.image}&quot;) center center / contain no-repeat; width: 36px; height: 36px; margin-right: 2px;`"
                  />
                  <div
                    class="anan-name"
                    :title="item.image"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="od-pc-offer-line">
              <div class="division-line" />
            </div>
            <div class="pc-sku-wrapper">
              <div
                style="
                  position: relative;
                  border-bottom: 1px solid rgb(233, 233, 233);
                "
              >
                <div
                  class="sku-module-wrappers sku-scene-wrapper"
                  style="padding-top: 15px; overflow: hidden"
                  :style="{ height: isHided ? 'auto' : '200px' }"
                >
                  <div
                    class="text-truncate"
                    style="width: 100px;"
                  >
                    {{ $t("color") }} {{ Color.title === '' || Color.title === null ? Color.id: Color.title }}
                  </div>
                  <div style="flex: 1 1 0%">
                    <div
                      v-for="(item, index) in Basket.filter(
                        (item) => item.color_id === Color.id
                      )"
                      id="sku-count-widget-wrapper"
                      :key="index"
                      class="count-widget-wrapper"
                    >
                      <div
                        class="d-flex justify-content-between ml-2 pb-1 align-items-center"
                        style="gap:20px;"
                      >
                        <div>
                          <div
                            v-if="item.fileimg"
                            style="font-weight: bold;"
                            class="mb-0"
                          >ไฟล์แนบ:
                            <img
                              :src="item.fileimg"
                              class="cursor-pointer"
                              alt="Preview"
                              width="35px"
                              @click="showPicture(item.fileimg)"
                            >
                          </div>
                        </div>
                        <div>
                          <div
                            v-if="item.remark"
                            style="font-weight: bold;width: 150px;"
                            class="text-truncate"
                          >
                            หมายเหตุ: {{ item.remark }}
                          </div>
                        </div>
                        <button
                          class="btn btn-outline-danger btn-sm"
                          style="padding: 6px 2px 6px 2px;"
                          @click="Showfile(item)"
                        >
                          แนบไฟล์/หมายเหตุ
                        </button>
                      </div>

                      <div class="sku-item-wrapper">
                        <div class="sku-item-left">
                          <div class="discountPrice-price">
                            <span class="price-text mr-2">
                              {{ item.size_title }}
                            </span>
                            <div class="od-pc-offer-price-common">
                              <img
                                v-if="item.size_img"
                                class="responsive"
                                width="50"
                                height="50"
                                :src="item.size_img"
                                :alt="item.size_img"
                              >
                            </div>
                            <span
                              class="price-unit"
                            >¥{{ item.price }} &nbsp;&nbsp;
                              <span
                                class="text-warning"
                              >฿{{ Commas(item.price * Rate) }}</span>
                            </span>
                            <!-- <span class="price-unit2"></span> -->
                          </div>

                          <div
                            v-if="item.quantity > 0"
                            class="sku-item-sale-num"
                          >
                            {{ $t("key-169") }} {{ item.quantity }}
                            {{ $t("key-111") }}
                          </div>
                          <div
                            v-else
                            style="color: red !important;"
                            class="sku-item-sale-num"
                          >
                            <span class="font-weight-bolder"> {{ $t('outOfStock') }}</span>
                          </div>
                        </div>

                        <div>

                          <vue-numeric-input
                            v-model="item.order"
                            :disabled="item.quantity <= 0"
                            :min="0"
                            :max="item.quantity"
                            :step="1"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="sku-wrapper-expend-button" />
              </div>
            </div>
            <button
              class="btn-show-more"
              @click="isHided = !isHided"
            >
              show more {{ isHided ? "▲" : "▼" }}
            </button>

            <!-- <div class="od-pc-offer-line">
                <div class="division-line" />
                รายการที่เลือก
                <div>
                  <div>
                    <div
                      v-for="(itemsOrder, color_id) in filteredOrders"
                      :key="color_id"
                    >
                      <h2>{{ color_id }}</h2>
                      <ul>
                        <li
                          v-for="(item, index) in itemsOrder"
                          :key="index"
                        >
                          <p v-if="item.size_title">
                            Size: {{ item.size_title }}
                          </p>
                          <p>Price: {{ item.price }}</p>
                          <p>Quantity: {{ item.quantity }}</p>
                          <p>Order: {{ item.order }}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> -->
            <!-- <div>
                <table style="width: 100%">
                  <colgroup>
                    <col width="20%">
                    <col width="20%">
                    <col width="30%">
                    <col width="30%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>{{ orderSum }} ชิ้น</td>
                      <td>¥{{ priceSum }}</td>
                      <td>฿{{ Commas(priceSum * Rate) }}</td>
                      <td class="text-center">
                        รายการที่เลือก
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            <div
              v-if="orderSum > 0"
              class="order-price-wrapper"
            >
              <div class="order-left-wrapper">
                <div class="total-count">
                  {{ $t("total") }} {{ Commas(orderSum) }} {{ $t("key-111") }}
                </div>
                <div class="total-price">
                  <div>
                    {{ $t("totalPrice") }}:
                    <span class="values"> {{ Commas(priceSum) }} ¥ </span>
                  </div>
                </div>
                <span
                  class="values"
                  style="padding-left: 12px"
                >
                  {{ Commas(priceSum * Rate) }} ฿
                </span>
              </div>
              <div
                class="order-has-select-button"
                @click="showIs = !showIs"
              >
                <span class="order-has-select-line" />
                {{ $t("key-171") }}
                <span :class="`sanjiao ${showIs ? 'show' : ''}`" />
              </div>

              <div
                v-if="showIs"
                class="selected-list-wrapper"
              >
                <div
                  v-for="(itemsOrder, color_id) in filteredOrders"
                  :key="color_id"
                >
                  <div
                    v-for="(item, index) in itemsOrder"
                    :key="index"
                    class="selected-item-wrapper"
                  >
                    <div class="name">
                      {{ item.color_title }}
                    </div>
                    <div class="total">
                      {{ item.order }} {{ $t("key-111") }}
                    </div>
                    <div class="children-item">
                      <div class="children-wrapper">
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <span v-if="item.size_title">
                            {{ item.size_title }}
                          </span>

                          <div class="sku-item-wrapper mr-1">
                            <!-- <b-form-spinbutton
                              id="sb-inline"
                              v-model="item.order"
                              inline
                              :max="item.quantity"
                              min="0"
                            /> -->

                            <vue-numeric-input
                              v-model="item.order"
                              :min="0"
                              :max="item.quantity"
                              :step="1"
                              @input="CheckValue(item)"
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button
                class="btn btn-gradient-primary"
                @click="AddCart()"
              >
                <i class="fa fa-shopping-cart" /> Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="Product.product_name"
        class="card p-1"
      >
        <div class="od-pc-offer-title-contain">
          <div class="title-content">
            <div class="title-first-column">
              <div class="title-text">
                {{ $t("productDetails") }}
              </div>
            </div>
            <div class="title-second-column">
              <div class="title-text title-second-text" />
            </div>
          </div>
        </div>

        <div
          class="content-container"
          v-html="Product.desc"
        />

        <!-- {{ Product.desc }} -->
        <!-- <div
          v-for="(item) in Product.external_image"
          :key="item.image"
          class="text-center"
        >
          <img
            :src="item.image"
            alt=""
            class="img-fluid"
          >
        </div> -->
      </div>
    </div>
    <b-modal
      id="modal-dashboard"
      title="แนบไฟล์ / หมายเหตุ"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
      cancel-variant="outline-primary"
      @ok="submitfile()"
    >
      <b-form-group>
        <label for="emailTranfer">{{ $t('attachedFile') }} :</label>
        <div
          class="clrr-settings mt-25 cursor-pointer"
          style="width: 150px;"
        >
          <div
            v-if="fileimg"
            style="position: relative;"
          >
            <img
              :src="fileimg"
              alt="Preview"
              class="img-thumbnail profile-img shadow-sm"
              width="150"
              height="150"
              @click="showPicture(fileimg)"
            >

            <i
              style="
              position: absolute;
              top: 0;
              right: -5px;
              color: #b60505;
              "
              class="fas fa-times-circle cursor-pointer"
              @click="deleteImageorder()"
            />
          </div>
          <div
            v-else
            class="input-file-container cursor-pointer"
            style="width: 100px; height: 100px;"
          >
            <i
              class="fal fa-plus cursor-pointer mr-0"
              style="font-size: 20px;"
            />
            {{ $t('key-9') }}
            <input
              id="img-file"
              ref="fileInput"
              type="file"
              class="input-file"
              accept="image/*"
              multiple
              @change="handleFileChanges($event)"
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <label for="addrCN">หมายเหตุ :</label>
        <b-form-textarea
          id="addrCN"
          v-model="remark"
          placeholder="หมายเหตุ"
        />
      </b-form-group>

    </b-modal>
  </div>
</template>

<script>
import {
  BFormSpinbutton, BOverlay,BAlert
  // BIconController,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import SkeletonLoaderVue from 'skeleton-loader-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueNumericInput from 'vue-numeric-input'

export default {
  components: {
    BFormSpinbutton,
    SkeletonLoaderVue,
    BOverlay,
    // BIconController,
    // ThisHeader,
    VueNumericInput,
    BAlert
  },
  data() {
    return {
      isHided: false,
      value: 1,
      data: {},
      items: [],
      selectedImg: '',
      actv: 0,
      actv_color: 0,
      ID: this.$route.params.id,
      Product: {
        external_image: [
          {
            image: '',
            thumnail: '',
          },
        ],
        product_name: '',
        shop_name: '',
        url: '',
        unit: '',
        color: {
          title: '',
          list: [{
            id: '',
            image: '',
          }],
        },
        min_price: 0,
        max_price: 0,
        priceRange: [],
      },
      Basket: [],
      url_link: '',
      ShowProduct: false,
      Rate: 0,
      ShowOrder: [],
      Color: '',
      showIs: false,
    }
  },
  // watch: {
  //   Product: {
  //     handler(newItems) {
  //       console.log('newItems', newItems)
  //       if (newItems.length > 0 && !this.selectedImg) {
  //         this.selectedImg = newItems[0].src
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUserslist()
  //     this.GetData(this.$route.params.id)
  //   }, 10000)
  // },
  computed: {
    filteredOrders() {
      if (this.Product.product_name) {
        const groupedData = {}
        const filteredSizes = this.Basket.filter(size => size.order > 0)
        filteredSizes.forEach(obj => {
          const { color_id, ...rest } = obj
          const order = Number(rest.order)
          if (this.Product.priceRange && this.Product.priceRange.length > 0) {
            // eslint-disable-next-line
          for (const index in this.Product.priceRange) {
              const range = this.Product.priceRange[index]
              const [orderQuantity, orderPrice] = range
              if (Number(index) !== this.Product.priceRange.length - 1) {
              // eslint-disable-next-line no-unused-vars
                const [nextOrderQua, nextOrderPice] = this.Product.priceRange[Number(index) + 1]
                if (order >= orderQuantity && order < nextOrderQua) {
                  // eslint-disable-next-line no-param-reassign
                  obj.price = orderPrice
                  rest.price = orderPrice
                  break
                }
              } else if (order >= orderQuantity) {
                // eslint-disable-next-line no-param-reassign
                obj.price = orderPrice
                rest.price = orderPrice
              } else {
                // eslint-disable-next-line
                obj.price = this.Product.priceRange[0][1]
                // eslint-disable-next-line prefer-destructuring
                rest.price = this.Product.priceRange[0][1]
              }
            }
          }

          if (groupedData[color_id]) {
            groupedData[color_id].push(rest)
          } else {
            groupedData[color_id] = [rest]
          }
        })
        return groupedData
      }
      return []
    },
    orderSum() {
      if (this.Product.product_name) {
        let totalOrder = 0
        this.Basket.forEach(product => {
          totalOrder += product.order
        })
        return totalOrder
      }
      return 0
    },
    priceSum() {
      if (this.Product.product_name) {
        let totalPrice = 0
        const filteredSizes = this.Basket.filter(size => size.order > 0)
        filteredSizes.forEach(product => {
          totalPrice += product.price * product.order
        })
        return totalPrice
      }
      return 0
    },
  },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    this.GetData(this.ID)
  },
  methods: {
    imgShow(src) {
      if (src) {
        this.selectedImg = src
      }
    },
    actImg(id) {
      this.actv = id
    },
    CheckValue(data) {
      const index = this.Basket.findIndex(item => item.color_title === data.color_title)
      if (index !== -1) {
        // กำหนดค่า order ให้กับ Basket ที่พบ
        this.$set(this.Basket[index], 'order', data.order)
      }
    },
    async GetData(ID) {
      this.ShowProduct = true
      try {
        const obj = {
          provider: 'market.taobao',
          ID,
        }
        const { data: response } = await this.$http.post('/product/search', obj)
        if (response.status) {
          this.Product = response.data
          this.selectedImg = this.Product.main_image
          this.CalStock(this.Product)
          await this.GetRate()
          // this.ShowProduct = false
        } else {
          this.$router.push({ name: 'new-pages' })
        }
      } catch (error) {
        console.log(error)
        this.Alert(false, 'ไม่พบสินค้า', '')
        this.$router.push({ name: 'new-pages' })
      }
    },
    async CalStock(Product) {
      try {
        if (Product.skus) {
          if (Product.color.title) {
            this.Color = {
              id: Product.color.list[0].id,
              title: Product.color.list[0].title || null,
            }
          }
          if (!Product.color.title.length) {
            this.Color = {
              id: Product.size.list[0].id,
              title: Product.size.list[0].title || null,
            }
          }
          // if (Product.skus["0"] && Object.keys(Product.skus).length > 1) {
          //   // eslint-disable-next-line no-param-reassign
          //   delete Product.skus["0"];
          // }
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(Product.skus)) {
            const obj = {
              product_name: Product.product_name || null,
              shop_name: Product.shop_name || null,
              unit: Product.unit || null,
              url: Product.url || null,
              color_id: '',
              color_title: '',
              color_img: '',
              size_id: '',
              size_title: '',
              size_img: '',
              price: value.price,
              quantity: value.quantity,
              order: 0,
            }
            if (Product.color.title && Product.size.title) {
              // eslint-disable-next-line prefer-destructuring
              obj.color_id = key.split(';')[0]
              const IndexColor = Product.color.list.findIndex(
                size => size.id === obj.color_id,
              )
              if (IndexColor !== -1) {
                obj.color_title = Product.color.list[IndexColor].title
                obj.color_img = Product.color.list[IndexColor].image || ''
              }
              // eslint-disable-next-line prefer-destructuring
              obj.size_id = key.split(';')[1]
              const IndexSize = Product.size.list.findIndex(
                size => size.id === obj.size_id,
              )
              if (IndexSize !== -1) {
                obj.size_title = Product.size.list[IndexSize].title
                obj.size_img = Product.size.list[IndexSize].image || ''
              }
            } else if (Product.color.title && !Product.size.title) {
              obj.color_id = key
              const IndexColor = Product.color.list.findIndex(
                size => size.id === key,
              )
              if (IndexColor !== -1) {
                obj.color_title = Product.color.list[IndexColor].title
                obj.color_img = Product.color.list[IndexColor].image || ''
              }
            } else if (
              Product.size.title
              && Object.keys(Product.skus).length === 1
            ) {
              const IndexSize = Product.size.list.findIndex(
                size => size.id === key,
              )
              if (IndexSize !== -1) {
                obj.size_id = Product.size.list[IndexSize].id
                obj.size_title = Product.size.list[IndexSize].title
                obj.size_img = Product.size.list[IndexSize].image || ''
              }
            } else if (Product.size.list.length && !Product.color.list.length) {
              const IndexColor = Product.size.list.findIndex(
                size => size.id === key,
              )
              if (IndexColor !== -1) {
                obj.color_id = Product.size.list[IndexColor].id
                obj.color_title = Product.size.list[IndexColor].title
                obj.color_img = Product.size.list[IndexColor].image || ''
              }
            } else if (Product.size.title) {
              const IndexSize = Product.size.list.findIndex(
                size => size.id === key,
              )
              if (IndexSize !== -1) {
                obj.size_id = Product.size.list[IndexSize].id
                obj.size_title = Product.size.list[IndexSize].title
                obj.size_img = Product.size.list[IndexSize].image || ''
              }
            } else if (Product.color.title) {
              const IndexColor = Product.color.list.findIndex(
                size => size.id === key,
              )
              if (IndexColor !== -1) {
                obj.color_id = Product.color.list[IndexColor].id
                obj.color_title = Product.color.list[IndexColor].title
                obj.color_img = Product.color.list[IndexColor].image || ''
              }
            }
            this.Basket.push(obj)
          }
          this.Basket.sort((a, b) => {
            // eslint-disable-next-line radix
            const numA = parseInt(a.size_title)
            // eslint-disable-next-line radix
            const numB = parseInt(b.size_title)
            return numA - numB
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get('/product/rate')
        if (res.status) {
          this.Rate = res.data.cSelling
          this.ShowProduct = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    AddCart() {
      const Order = this.Basket.filter(product => product.order > 0)
      const obj = {
        storename: Order[0].shop_name,
        product_name: Order[0].product_name,
        product_url: Order[0].url,
        product_unit: Order[0].unit,
        price_range: this.Product.priceRange,
        product_img: this.Product.external_image.length ? this.Product.external_image[0].image: Order[0].color_img,
        provider: 'market.taobao',
        order_list: Order,
      }
      this.$http
        .post('/cart/addother', obj)
        .then(() => {
          this.$swal({
            title: 'เพิ่มสินค้าลงตะกร้าสำเร็จ',
            text: 'คุณต้องการไปที่ตะกร้าสินค้าหรือไม่ ?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'cart' })
            }
          })
        })
        .catch(error => {
          console.log(error)
          this.Alert(false, 'เพิ่มสินค้าลงตะกร้าไม่สำเร็จ', '')
        })
    },
    async GetProductByUrl() {
      const obj = {
        provider: '',
        id: '',
      }
      const url = new URL(this.url_link)
      const { host, pathname, search } = url
      if (host && host.length > 0) {
        const index1688 = host.indexOf('1688')
        const indextaobao = host.indexOf('taobao')
        const indextmall = host.indexOf('tmall')
        if (index1688 > -1) {
          obj.provider = '1688'
          if (host === 'mindx.1688.com') {
            obj.id = url.searchParams.get('offerId')
          } else {
            const IndexCheck = pathname.indexOf('/offer/')
            if (IndexCheck > -1) {
              const of = new RegExp('/offer/', 'g')
              const ht = new RegExp('.html', 'g')
              obj.id = pathname.replace(of, '')
              obj.id = obj.id.replace(ht, '')
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = 'taobao'
          const IndexCheck = search.indexOf('itemIds')
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemIds')
          } else {
            obj.id = url.searchParams.get('id')
          }
        } else if (indextmall > -1) {
          obj.provider = 'tmall'
          obj.id = url.searchParams.get('id')
        }
        if (obj.provider && obj.id) {
          if (this.$route.name !== `product-${obj.provider}`) {
            this.$router.push({ name: `product-${obj.provider}`, params: { id: obj.id } })
          } else {
            this.$router.push({ name: `product-${obj.provider}`, params: { id: obj.id } })
            window.location.reload()
          }
        } else {
          this.Alert(false, 'ไม่พบข้อมูลสินค้า', 'กรุณากรอก URL ใหม่ให้ถูกต้อง')
        }
      }
    },
    openNewTab(url) {
      window.open(url, '_blank')
    },
    copyText(Text) {
      const textField = document.createElement('textarea')
      textField.innerText = Text
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      this.Alert(true, 'คัดลอกข้อความสำเร็จ', Text)
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn-show-more {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  /* margin-top: -10px; */
  border-radius: 0 0 4px 4px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  line-height: 30px;
  /* margin: 0; */
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  outline: none;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(129, 129, 129, 0.329);
}
.anan-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
}

.anan-item-wrapper .anan-item {
  margin-right: 20px;
  width: 150px;
  max-width: 200px;
}

.anan-item-wrapper .anan-item .anan-item-inner-wrapper {
  padding: 1px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ededed;
  align-items: center;
}

.anan-item-wrapper .anan-item .anan-item-inner-wrapper.active {
  /* border: 2px solid #0037ad; */
  box-shadow:  0 0 0px 2px #0037ad;
  border-radius: 2px;
}

.anan-item-wrapper .anan-item .anan-item-inner-wrapper .anan-img {
  width: 36px;
  height: 36px;
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
}

.anan-item-wrapper .anan-item .anan-item-inner-wrapper .anan-name {
  font-size: 12px;
  /* text-overflow: ellipsis; */
  line-height: 40px;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
